import "stylesheets/application.scss"
import "bootstrap"
import "jquery-ujs"
import "select2"
import "fontawesome"
import "images"
import Chart from "chart.js"

Chartkick.addAdapter(Chart)

$(function () {
  $('[data-toggle="tooltip"]').tooltip()

  $(".toggle-visible").on("click", function (e) {
    e.preventDefault()
    $(this.getAttribute("href")).toggleClass("d-none")
  })

  $('select').select2({
    theme: 'bootstrap4',
    width: '100%'
  })
})
