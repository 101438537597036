import {
  library,
  dom
} from "@fortawesome/fontawesome-svg-core";
import {
  faCheck
} from '@fortawesome/pro-solid-svg-icons/faCheck'
import {
  faTimes
} from '@fortawesome/pro-solid-svg-icons/faTimes'
import {
  faDownload
} from '@fortawesome/pro-solid-svg-icons/faDownload'
import {
  faClock
} from '@fortawesome/pro-solid-svg-icons/faClock'
import {
  faSkullCrossbones
} from '@fortawesome/pro-solid-svg-icons/faSkullCrossbones'
import {
  faEye
} from '@fortawesome/pro-solid-svg-icons/faEye'
import {
  faNetworkWired
} from '@fortawesome/pro-regular-svg-icons/faNetworkWired'
import {
  faSlash
} from '@fortawesome/pro-regular-svg-icons/faSlash'
import {
  faGoogle
} from '@fortawesome/free-brands-svg-icons/faGoogle'
import {
  faWordpress
} from '@fortawesome/free-brands-svg-icons/faWordpress'

library.add(faCheck, faTimes, faDownload, faClock, faSkullCrossbones, faEye, faNetworkWired, faSlash, faGoogle, faWordpress);
dom.watch()
